<script>
import moment from 'moment'
import md5 from 'md5'

export default {
  data() {
    return {
      needPassword: false,
      tokenPassword: "",
      loading_screen: true,
      screen_mode: "",
    }
  },
  // created(){
  //   this.age_range = this.LocalStorage("_age_range_",[]);
  //   this.union_status = this.LocalStorage("_union_status_",[]);
  //   this.delivery_cap = this.LocalStorage("_delivery_cap_",[]);
  //   this.ethnicities = this.LocalStorage("_ethnicities_",[]);
  //   this.languages = this.LocalStorage("_languages_",[]);
  //   this.countries = this.LocalStorage("_countries_",[]);
  //   this.vocal_type = this.LocalStorage("_vocal_type_",[]);
  //   this.com_type = this.LocalStorage("_com_type_",[]);
  //   this.demo_cat = this.LocalStorage("_demo_cat_",[]);
  //   this.accents = this.LocalStorage("_accents_",[]);
  // },
  methods: {
    LocalStorage(name, set = new Object()) {
      var taskArrayString = localStorage.getItem(name);
      if (taskArrayString !== null && typeof taskArrayString === "string") {
        // console.log("Succesfully retrieved 'tasks' and contents.");
      } else {
        // console.log("Succesfully retrieved 'tasks', contents were not a string or held no contents. Contents of 'tasks' reset to empty object");
        var emptyArray = JSON.stringify(set);
        localStorage.setItem(name, emptyArray);
        taskArrayString = localStorage.getItem(name);
      }
      return JSON.parse(taskArrayString);
    },
    md5(val) {
      return md5(val);
    },
    redirect(path) {
      if (this.$route.path !== path) this.$router.push(path)
    },
    set_data(data, array) {
      if (Array.isArray(data)) return data;

      let Val = [];
      let Data = data.split(",");
      Data.forEach((e) => {
        let index = array.findIndex(x => x.id == e);
        if (index !== -1) Val.push(array[index]);
      });
      return Val
    },
  },
  filters: {
    moment(date) {
      return moment(date).format('Do MMMM YYYY');
    },
    date_message: function (date) {
      return moment(date).format('D MMM, YYYY h:mm a');
    },
    date_project: function (date) {
      return moment(date).format('MMMM Do, YYYY h:mm a') + " (PST)";
    },
    date_approval: function (date) {
      return moment(date).format('D MMM, YYYY h:mm a') + " (PST)";
    },
    date_list: function (date) {
      var fromNow = moment(date).fromNow();
      return moment(date).calendar(null, {
        lastWeek: '[Last] dddd',
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        sameElse: function () {
          return "[" + fromNow + "]";
        }
      });
    },
    highlight: function (words, query) {
      return words.replace(query, '<span class="highlight">' + query + '</span>')
    }
  }
};
</script>
