<template>
  <div id="app">
    <router-view class="bootstrap-wrapper" :input="input" @set_input="set_input" />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      input: {}
    }
  },
  beforeCreate() {
    if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
      console.log("%c It's a local server! ", 'background: #222; color: #bada55');
    } else {
      if (location.protocol !== 'https:') {
        location.replace(`https:${location.href.substring(location.protocol.length)}`);
      }
    }
  },
  methods: {
    set_input(input) {
      this.input = input;
    },
  }
}
</script>

<!-- http://localhost:8080/#/logout/auncgn@gmail.com/25f9e794323b453885f5181f1b624d0b **************************************************************** -->

<style>
@import url(https://fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap);

#app {
  font-family: 'Prompt', sans-serif;
  font-weight: 300;
}

.w-100 {
  width: 100% !important;
}

.text-center {
  align-items: center !important;
}

.cursor_pointer {
  cursor: pointer !important;
}

.swal-text {
  text-align: center !important;
}

.swal-footer {
  text-align: center !important;
}

.float-right {
  float: right !important;
}

.multiselect__tag {
  background: #3273dc !important;
}

.multiselect__tag-icon:after {
  color: #fff !important;
}

.multiselect__content {
  margin: 0 !important;
}

.show-mobile {
  display: none !important;
}

@media screen and (max-width: 767px) {

  /* (max-width: 600px) */
  .hidden-mobile {
    display: none !important;
  }

  .show-mobile {
    display: block !important;
  }
}

/* ................................................. */
.transform-none {
  text-transform: none !important;
}

.cut-text {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  display: none !important;
  /* width: 160px;  */
  /* height: 1.2em;  */
}

.photo-album {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  /* height: 175px !important; */
}

.photo-cover {
  width: 100%;
  height: 375px !important;
  object-fit: cover !important;
}

@media screen and (max-width: 992px) {

  /* (max-width: 767px) */
  .photo-cover {
    height: 275px !important;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.show {
  display: block !important;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.avatar-3xl {
  width: 245px !important;
  height: 245px !important;
}
</style>
